import React, { useState, useEffect } from 'react';
import { Phone, Clock, MapPin, Menu, X, Activity, Heart, Syringe, MessageCircle, ArrowUp } from 'lucide-react';
import { trackPageView, trackClick } from './utils/analytics';
import LoadingSpinner from './components/LoadingSpinner';

// Import optimized images - These paths should match your actual image locations
//import clinicFacade from './images/clinic-facade.webp';
import clinicInterior from './images/clinic-interior.webp';
import servicesBoard from './images/services-board.webp';

const ClinicWebsite = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Track page view
    trackPageView('home');
    
    // Initial loading state
    const loadTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Scroll handler
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      clearTimeout(loadTimer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleWhatsAppClick = () => {
    trackClick('whatsapp_contact');
  };

  const handlePhoneClick = () => {
    trackClick('phone_call');
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Floating Action Buttons */}
      <div className="fixed bottom-6 right-6 flex flex-col gap-3 z-50">
        <a
          href="https://wa.me/60333234405"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleWhatsAppClick}
          className="bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-lg transition-transform hover:scale-110"
          aria-label="Contact on WhatsApp"
        >
          <MessageCircle className="h-6 w-6" />
        </a>

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="bg-blue-800 hover:bg-blue-700 text-white p-3 rounded-full shadow-lg transition-transform hover:scale-110"
            aria-label="Scroll to top"
          >
            <ArrowUp className="h-6 w-6" />
          </button>
        )}
      </div>

      {/* Emergency Notice */}
      <div className="bg-red-500 text-white py-2 px-4 text-center text-sm">
        <div className="max-w-6xl mx-auto">
          For emergencies, please call{' '}
          <a href="tel:+60333234405" onClick={handlePhoneClick} className="font-bold underline">
            +603-3323 4405
          </a>
          <span className="hidden md:inline"> • Mon-Sat: 8AM-8PM • Sun: 9AM-4:30PM</span>
        </div>
      </div>

      {/* Navigation */}
      <nav className="bg-white shadow-lg fixed w-full z-40">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex flex-col">
              <span className="text-2xl font-bold text-blue-800">Poliklinik Kanna dan Surgeri</span>
              <div className="text-sm text-gray-600">பொலி கிளினிக் கண்ணா • 甘邦医务所</div>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#services" className="text-gray-600 hover:text-blue-800 transition-colors">Services</a>
              <a href="#location" className="text-gray-600 hover:text-blue-800 transition-colors">Location</a>
              <a 
                href="tel:+60333234405"
                onClick={handlePhoneClick}
                className="bg-blue-800 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
              >
                <Phone className="h-4 w-4" />
                +603-3323 4405
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Toggle menu"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden bg-white shadow-lg">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <a href="#services" className="block px-3 py-2 text-gray-600 hover:text-blue-800 transition-colors">Services</a>
                <a href="#location" className="block px-3 py-2 text-gray-600 hover:text-blue-800 transition-colors">Location</a>
              </div>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      {/* <section className="pt-24 pb-16 bg-gradient-to-b from-blue-900 to-blue-800 text-white relative">
          <div 
          className="absolute inset-0 opacity-20 bg-cover bg-center"
          style={{ backgroundImage: `url(${clinicFacade})` }}
          aria-hidden="true"
        ></div> */}


        <section className="pt-24 pb-16 relative overflow-hidden">
          {/* Background */}
          <div className="absolute inset-0 bg-gradient-to-b from-blue-900 to-blue-800"></div>
          
          {/* Glass effect elements */}
          <div className="absolute inset-0">
            <div className="absolute top-20 left-20 w-72 h-72 bg-white/5 rounded-full blur-3xl"></div>
            <div className="absolute bottom-10 right-10 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"></div>
          </div>
          
        <div className="relative z-10 max-w-6xl mx-auto px-4">
          <div className="text-center">
            <div className="mb-8">
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                Your Trusted Healthcare Partner
              </h1>
              <div className="text-xl text-gray-200 max-w-2xl mx-auto">
                Comprehensive medical services in Bukit Tinggi, Klang. 
                Open daily with extended hours for your convenience.
              </div>
            </div>

            {/* Contact Info */}
            <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 inline-block">
              <a 
                href="tel:+60333234405"
                onClick={handlePhoneClick}
                className="text-2xl md:text-3xl font-bold text-white hover:text-blue-100 flex items-center justify-center gap-2"
              >
                <Phone className="h-6 w-6" />
                +603-3323 4405
              </a>
              <div className="text-gray-200 mt-2">
                <div>Mon-Sat: 8:00 AM - 8:00 PM</div>
                <div>Sun: 9:00 AM - 4:30 PM</div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-center gap-4 max-w-md mx-auto">
              <a
                href="tel:+60333234405"
                onClick={handlePhoneClick}
                className="bg-white text-blue-900 px-8 py-4 rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center gap-2 font-semibold"
              >
                <Phone className="h-5 w-5" />
                Call Now
              </a>
              <a
                href="#location"
                className="bg-transparent text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-colors border border-white flex items-center justify-center gap-2"
              >
                <MapPin className="h-5 w-5" />
                Get Directions
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Diagnostic Services */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Activity className="h-8 w-8 text-blue-800 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Diagnostic Services</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• X-Ray Services</li>
                <li>• Ultrasound</li>
                <li>• ECG</li>
                <li>• Spirometer</li>
                <li>• Blood Tests</li>
                <li>• Dengue Test</li>
              </ul>
            </div>

            {/* Medical Services */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Heart className="h-8 w-8 text-blue-800 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Medical Services</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Internal Medicine</li>
                <li>• Ear, Nose & Throat</li>
                <li>• Allergy Treatment</li>
                <li>• Minor Surgery</li>
                <li>• Medical Checkup</li>
                <li>• Med-Track</li>
              </ul>
            </div>

            {/* Special Services */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Syringe className="h-8 w-8 text-blue-800 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Special Services</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• FOMEMA Examination</li>
                <li>• Panel Clinic Services</li>
                <li>• Immunisation</li>
                <li>• Traveller's Vaccination</li>
              </ul>
            </div>
          </div>

          {/* Service Images */}
          <div className="mt-12 grid md:grid-cols-2 gap-8">
            <img 
              src={servicesBoard} 
              alt="Our Medical Services Board"
              className="rounded-lg shadow-lg w-full h-64 object-cover"
              loading="lazy"
            />
            <img 
              src={clinicInterior} 
              alt="Inside Our Modern Facility"
              width="400"  // Specify exact dimensions
              height="300"
              className="rounded-lg shadow-lg w-full h-64 object-cover"
              loading="lazy"
              decoding="async"
              fetchpriority="low"
            />
          </div>
        </div>
      </section>


      {/* Trust Building Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Us</h2>
          
          {/* Experience Highlight */}
          <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
            <div className="text-center">
              <h3 className="text-2xl font-bold text-blue-800 mb-4">15+ Years of Trusted Healthcare</h3>
              <p className="text-gray-600 max-w-3xl mx-auto">
                Serving the Bukit Tinggi and Klang community since 2008, we've built a reputation for reliable, 
                comprehensive healthcare services. Our commitment to quality care has made us a trusted name in the region.
              </p>
            </div>
          </div>


      {/* Patient Reviews Section */}
        <div className="grid md:grid-cols-3 gap-6">
          {/* English Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★★</div>
            <p className="text-gray-600 mb-4">
              "Very thorough health screening with clear explanation. Dr. Kanna explained 
              everything patiently. Extended hours made it convenient to visit after work."
            </p>
            <div className="font-semibold">- Sarah T.</div>
            <div className="text-sm text-gray-500">Health Screening Patient</div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★<span className="text-gray-200">★</span></div>
            <p className="text-gray-600 mb-4">
              "Quick and efficient service. The clinic is clean and well-organized. 
              Wait time was reasonable. Will recommend to friends."
            </p>
            <div className="font-semibold">- Michael L.</div>
            <div className="text-sm text-gray-500">Regular Patient</div>
          </div>

          {/* Malay Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★★</div>
            <p className="text-gray-600 mb-4">
              "Perkhidmatan sangat profesional. Pemeriksaan FOMEMA dijalankan dengan teliti. 
              Lokasi mudah dicari dan masa menunggu tidak lama."
            </p>
            <div className="font-semibold">- Ahmad R.</div>
            <div className="text-sm text-gray-500">FOMEMA Patient</div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★<span className="text-gray-200">★</span></div>
            <p className="text-gray-600 mb-4">
              "Klinik yang bersih dan selesa. Doktor sangat prihatin dan memberikan 
              nasihat yang jelas. Harga berpatutan."
            </p>
            <div className="font-semibold">- Nurul H.</div>
            <div className="text-sm text-gray-500">Medical Checkup Patient</div>
          </div>

          {/* Chinese Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★★</div>
            <p className="text-gray-600 mb-4">
              "医生很专业，服务态度好。解释得很详细。营业时间方便，
              是我们家庭首选的诊所。"
            </p>
            <div className="font-semibold">- 陈慧君</div>
            <div className="text-sm text-gray-500">Regular Patient</div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★<span className="text-gray-200">★</span></div>
            <p className="text-gray-600 mb-4">
              "血压检查和心电图检查服务很好，医生讲解详细。
              位置方便，环境干净。"
            </p>
            <div className="font-semibold">- 林志明</div>
            <div className="text-sm text-gray-500">ECG Patient</div>
          </div>

          {/* Tamil Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★★</div>
            <p className="text-gray-600 mb-4">
              "மிகவும் நல்ல மருத்துவ சேவை. குடும்ப டாக்டராக நம்பிக்கையுடன் 
              வருகிறோம். விரைவான சேவை."
            </p>
            <div className="font-semibold">- ராஜன் K.</div>
            <div className="text-sm text-gray-500">Family Patient</div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★<span className="text-gray-200">★</span></div>
            <p className="text-gray-600 mb-4">
              "தரமான மருத்துவ சேவை. நோயாளிகளை அக்கறையுடன் 
              கவனித்துக்கொள்கிறார்கள். சிறந்த அனுபவம்."
            </p>
            <div className="font-semibold">- சுமதி P.</div>
            <div className="text-sm text-gray-500">Health Screening Patient</div>
          </div>

          {/* General Review */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-yellow-400 mb-4">★★★★★</div>
            <p className="text-gray-600 mb-4">
              "Professional service and friendly staff. The clinic is easily accessible 
              and well-maintained. Very satisfied with the treatment."
            </p>
            <div className="font-semibold">- David W.</div>
            <div className="text-sm text-gray-500">Corporate Panel Patient</div>
          </div>
        </div>


      {/* Key Statistics */}
        <div className="grid md:grid-cols-4 gap-6 mt-12">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-3xl font-bold text-blue-800 mb-2">15+</div>
            <div className="text-gray-600">Years of Service</div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-3xl font-bold text-blue-800 mb-2">50+</div>
            <div className="text-gray-600">Corporate Panels</div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-3xl font-bold text-blue-800 mb-2">10K+</div>
            <div className="text-gray-600">Patients Served</div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-3xl font-bold text-blue-800 mb-2">12+</div>
            <div className="text-gray-600">Services Offered</div>
          </div>
        </div>
      </div>
    </section>

      {/* Location Section */}
      <section id="location" className="py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Find Us</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-6">Our Location</h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <MapPin className="h-6 w-6 text-blue-800 flex-shrink-0 mt-1" />
                  <div>
                    <p className="font-semibold">Address:</p>
                    <p className="text-gray-600">40-00-1, Lorong Batu Nilam 4a, Bandar Bukit Tinggi 1, 41200, Klang, Selangor</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Phone className="h-6 w-6 text-blue-800 flex-shrink-0 mt-1" />
                  <div>
                    <p className="font-semibold">Phone:</p>
                    <p className="text-gray-600">+603-3323 4405</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Clock className="h-6 w-6 text-blue-800 flex-shrink-0 mt-1" />
                  <div>
                    <p className="font-semibold">Hours:</p>
                    <p className="text-gray-600">Monday - Saturday: 8:00 AM - 8:00 PM</p>
                    <p className="text-gray-600">Sunday: 9:00 AM - 4:30 PM</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-96 rounded-lg overflow-hidden shadow-lg">
              <iframe 
                title="Poliklinik Kanna dan Surgeri Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.322451660063!2d101.43586347597655!3d3.0076625540040274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdac9cb9be68af%3A0x195180bd6522e244!2sPoliklinik%20Kanna%20Dan%20Surgeri!5e0!3m2!1sen!2sin!4v1734704515469!5m2!1sen!2sin"
                  style={{
                  width: '100%',
                  height: '100%',
                  border: '0'
                }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-900 text-white py-8">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p className="mb-2">© 2024 Poliklinik Kanna dan Surgeri</p>
          <p className="text-sm text-gray-200">40-00-1, Lorong Batu Nilam 4a, Bandar Bukit Tinggi 1, 41200, Klang, Selangor</p>
        </div>
      </footer>
    </div>
  );
};

export default ClinicWebsite;