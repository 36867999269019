import React from 'react';

const LoadingSpinner = () => (
  <div className="min-h-screen bg-white flex items-center justify-center">
    <div className="text-center">
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-800 mb-4"></div>
      <p className="text-gray-600">Loading Poliklinik Kanna...</p>
    </div>
  </div>
);

export default LoadingSpinner;