// Track general events

export const GA_TRACKING_ID = 'G-S5LN3NKNZ5';

export const trackEvent = (eventName, eventData = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, {
      ...eventData,
      timestamp: new Date().toISOString(),
    });
  }
};

// Track page views
export const trackPageView = (page) => {
  trackEvent('page_view', { page });
};

// Track button/link clicks
export const trackClick = (element) => {
  trackEvent('click', { element });
};

// Track contact actions
export const trackContact = (method) => {
  trackEvent('contact', { method });
};

// Track specific actions
export const trackActions = {
  phoneCall: () => trackContact('phone'),
  whatsApp: () => trackContact('whatsapp'),
  locationView: () => trackEvent('location_view'),
  serviceView: (service) => trackEvent('service_view', { service }),
};